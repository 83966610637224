import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Aerospace Parts Manufacturing | Painted Rhino"
            description="You might have heard of concept design & engineering. If not, this term can be confusing. Concept engineering is straightforward, once you understand it. parts manufacturing is a process that involves the production of parts and components for use in aircraft and space vehicles. "
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Concept Design & Engineering – What Is it and Why Is it Important?"
            date="July 22, 2022"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     You might have heard of <a class="inline-link" href="https://www.paintedrhino.com/concept-design-engineering-company/">concept design and engineering</a> if you're developing a new product. Like a lot of industry jargon, this term can be confusing. But once you understand it, concept engineering is straightforward.

                  </p>

                  <p>Any product starts as a design on paper. Someone has to create that product, and it doesn't always come out as expected. Parts that look perfect on paper often require some smoothing out before they're perfect in real life.</p>

                  <p>
                     That's where concept engineering comes in. It's the process of turning your paper design into a real product.
                  </p>

                  <h2>What Is Concept Engineering?</h2>
                  <p>Engineering is an art as well as a science. When you design a part, you try to consider all the variables. How is it going to be used? Is it tough enough to withstand misuse? Is there some unforeseen flaw in the design?
                     Once the design is complete, the manufacturer must procure the necessary raw materials, including metals, composites, plastics,
                     and other materials.
                  </p>
                  <p>
                     Engineers are humans, and it's easy to overlook variables. So, all too often, prototypes don't work as expected, requiring you to go back to the drawing board.
                  </p>
                  <p>
                     Concept Engineering is an iterative process. First, you build a prototype, test it, and see if it works. If it does, great; you can produce as many as you need. If not, you change the design and keep trying until you have a working product.
                  </p>

                  <h2>Types of Concept Engineering</h2>
                  <p>
                     Concept engineering comes in many different forms. Here are some of the services you might see.
                  </p>
                  <h2>Hand Sculpting</h2>
                  <p>
                     Hand sculpting is probably the oldest type of concept engineering. An artist creates a plaster or clay sculpture, then uses that sculpture to create a mold. Skilled workers can use that mold to create multiple copies of a completed product.
                  </p>
                  <h2>CNC Sculpting</h2>
                  <p>Hand sculpting is great when you don’t need a high degree of precision. But for precision parts, CNC sculpting is far more accurate. A computer can cut materials ranging from foam to wood to aluminum. It’s far more precise than a human sculptor, and you can program it with common CAD software.</p>
                  <h2>CNC Milling</h2>
                  <p>
                     CNC milling is the process of creating completed products from a solid block. You can mill out complex designs from metals, plastics, and even high-quality glass. You can also mill ultra-hard materials like titanium unsuitable for sculpting.
                  </p>

                  <h2>3D Printing</h2>
                  <p>3D printing is a great way to create low-cost prototypes and proofs of concept. You build a design in CAD software, then load it into 3D printing software. The program then divides your model into thin “slices,” which the printer lays down one by one. It’s not ideal for making high-stress parts or for anything requiring extreme precision. But for many projects, it’s an ideal design method.</p>
                
                  <h2>Conclusion</h2>
                  <p>
                     Whether you’re looking for <a href="/fiberglass-composite-parts-manufacturing/">fiberglass or composite parts manufacturing</a>,
                     at Painted Rhino, we have the experience and expertise to produce high-quality parts and components that meet the strictest
                     quality standards. <a data-modal-open="modal-contact"> Contact us today</a>. Before you know it, your design-in-progress will be a finished product.


                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Painted Rhino_Service_ Concept Design & Engineering.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
